import React, { FC } from "react";
import { VerificationType } from "./VerificationButton/VerificationButton";
import ResultsPhone from "./Results/ResultsPhone";
import ResultsNIN from "./Results/ResultsNIN";
import ResultsCreditScore from "./Results/ResultsCreditScore";
import ResultsCreditReport from "./Results/ResultsCreditReport";

interface KYCResultsProps<T = any> {
  results: T;
  resultsType: VerificationType;
  handleRtryAgain?: () => void;
}

export const KYCResults: FC<KYCResultsProps> = (props) => {
  switch (props.resultsType) {
    case VerificationType.VERIFYNIN:
      return (
        <ResultsNIN
          data={props.results}
          handleTryAgain={props?.handleRtryAgain}
        />
      );
    case VerificationType.VERIFYPHONE:
      return (
        <ResultsPhone
          handleTryAgain={props?.handleRtryAgain}
          data={props.results}
        />
      );
    case VerificationType.CHECKCREDITSCORE:
      return <ResultsCreditScore data={props.results} />;
    case VerificationType.CHECKCREDITREPORT:
      return <ResultsCreditReport data={props.results} />;
    default:
      return <div>Invalid results type</div>;
  }
};

export default KYCResults;
