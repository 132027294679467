import { FC, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/useAuth";
import FormAsyncSelectSearch from "./FormAsyncSelectSearch";
import { isGranted } from "../../../utils/is_granted";
import { PERMISSIONS } from "../../../constants/permissions";

interface IFormBranchFieldProps {
  name: string;
  label: string;
  type?: string;
  parseFilter?: (...params: any) => any;
  filterValues?: any;
  dataFetcher?: (
    search: string,
    setData: (results: any) => void,
    extraFilters?: any,
  ) => Promise<any> | any;
  selector?: {
    value: (option: any) => any;
    label: (option: any) => any;
  };
  formControl: any;
  isDisabled?: boolean;
}

const FormBranchField: FC<IFormBranchFieldProps> = (
  props: IFormBranchFieldProps,
) => {
  const auth: any = useAuth();
  const [disableBranch, setDisableBranch] = useState(false);

  useEffect(() => {
    if (
      isGranted(auth.user, [PERMISSIONS.CROSS_BRANCH_ACCESS]) ||
      auth?.user?.branch_access
    ) {
      setDisableBranch(true);
    } else {
      setDisableBranch(false);
      setTimeout(() => {
        props.formControl.setFieldValue(props.name, {
          id: auth?.user?.extra_details?.branch_details?.id,
          name: auth?.user?.extra_details?.branch_details?.name,
        });
      });
    }
  }, [auth?.user?.type, auth?.user?.extra_details?.branch]);

  // set global branch if form has branch field [value should be saved in session storage, with key 'branch']
  useEffect(() => {
    if (props.formControl.values.branch) {
      sessionStorage.setItem(
        "branch",
        JSON.stringify(props.formControl.values.branch),
      );
    }

    // should clear the session if the branch input component is exited
    return () => {
      if (!props.formControl.values.branch) {
        sessionStorage.removeItem("branch");
      }
    };
  }, [props.formControl.values.branch]);

  return <FormAsyncSelectSearch isDisabled={!disableBranch} {...props} />;
};

export default FormBranchField;
