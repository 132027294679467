import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { formatDateToDDMMYYYY } from "../../../utils/date_formatter";
import { figureFormatter } from "../../../utils/figure_formatter";

interface IDetailsInfo {
  entityDetails: any;
}

const NonPhysicalAssetReversal: FC<IDetailsInfo> = ({ entityDetails }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, mb: 1.5, fontWeight: "bold" }}
          gutterBottom
        >
          Non Physical Asset Details
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Asset Name:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.name}
        </Typography>

        {entityDetails?.asset_type_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Asset Type:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.asset_type_display}
            </Typography>
          </>
        )}

        {entityDetails?.type_of_bond_sec_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Type Of Bond/Security:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.type_of_bond_sec_display}
            </Typography>
          </>
        )}

        {entityDetails?.branch_details && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Branch:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.branch_details?.name}
            </Typography>
          </>
        )}

        {entityDetails?.purchase_date && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Purchase Date:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {formatDateToDDMMYYYY(entityDetails?.purchase_date)}
            </Typography>
          </>
        )}

        {entityDetails?.purchase_price && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Purchase Price:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {figureFormatter({ figure: entityDetails?.purchase_price })}
            </Typography>
          </>
        )}

        {entityDetails?.issuer_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Issuer:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.issuer_display}
            </Typography>
          </>
        )}

        {entityDetails?.maturity_date && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Maturity Date:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {formatDateToDDMMYYYY(entityDetails?.maturity_date)}
            </Typography>
          </>
        )}

        {entityDetails?.payment_method_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Payment Method Display:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.payment_method_display}
            </Typography>
          </>
        )}

        {entityDetails?.redemption_features && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Redemption Features:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.redemption_features}
            </Typography>
          </>
        )}

        {entityDetails?.yield_to_maturity && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Yield To Maturity:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.yield_to_maturity}
            </Typography>
          </>
        )}

        {entityDetails?.is_taxable !== null && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Is Taxable:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.is_taxable ? "Yes" : "No"}
            </Typography>
          </>
        )}

        {entityDetails?.interest_frequency_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Interest Frequency Display:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.interest_frequency_display}
            </Typography>
          </>
        )}

        {entityDetails?.face_value && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Face Value:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.face_value}
            </Typography>
          </>
        )}

        {entityDetails?.custodian_information && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Custodian Information:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.custodian_information}
            </Typography>
          </>
        )}

        {entityDetails?.current_market_value && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Current Market Value:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {figureFormatter({ figure: entityDetails?.current_market_value })}
            </Typography>
          </>
        )}

        {entityDetails?.credit_rating && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Credit Rating:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.credit_rating}
            </Typography>
          </>
        )}

        {entityDetails?.coupon_rate && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Coupon Rate:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.coupon_rate}
            </Typography>
          </>
        )}

        {entityDetails?.comments && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Comments:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.comments}
            </Typography>
          </>
        )}

        {entityDetails?.collateral && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Collateral:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.collateral}
            </Typography>
          </>
        )}

        {entityDetails?.acc_number && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Acc Number:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.acc_number}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default NonPhysicalAssetReversal;
