export const CLIENT_ROUTES = [
  "/clients/individual",
  "/clients/institution",
  "/clients/group",
];

export const LOANS_ROUTES = [
  "/loans/applications",
  "/loans/active",
  "/loans/closed",
  "/loans/declined",
  "/loans/disbursement",
  "/loans/written-off-loans",
];

export const cleanUpGlobalBranchState = (status: boolean) => {
  if (status) {
    // cut the current selected branch to temp_branch
    if (sessionStorage.getItem("branch")) {
      sessionStorage.setItem(
        "temp_branch",
        sessionStorage.getItem("branch") || "{}",
      );
    }

    // clear the branch
    sessionStorage.removeItem("branch");
  } else {
    // clear the branch
    sessionStorage.removeItem("branch");

    // set the branch to the temp_branch
    if (sessionStorage.getItem("temp_branch")) {
      sessionStorage.setItem(
        "branch",
        sessionStorage.getItem("temp_branch") || "{}",
      );
    }

    // clear the temp_branch
    sessionStorage.removeItem("temp_branch");
  }
};
