import _ from "lodash";
import { IFormField } from "../../../../../utils/form_factory";

export const NINVerificationFields = (
  branchFetcher: any,
  checkField: string,
): IFormField[] => [
  {
    name: "branch",
    initailValue: "",
    label: "Branch *",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "branch-field",
    dataFetcher: branchFetcher,
    selector: {
      value: (option: any) => option?.id,
      label: (option: any) => option?.name,
    },
    parseFilter: (id: string) => {
      return _({}).omitBy(_.isUndefined).value();
    },
    initailValueReadPath: "branch",
  },
  {
    name: "nin",
    initailValue: "",
    label: "NIN *",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "nin",
    customFormat: "XXXXXXXXXXXXXX",
  },
  {
    name: "document_id",
    initailValue: "",
    label: "Card Number *",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "nin",
    customFormat: "XXXXXXXXXXXXXX",
  },
  {
    name: "applies_to_temp",
    initailValue: "",
    label: "Check against any of the following client details ",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
    initailValueReadPath: "type",
  },
  {
    name: "surname",
    initailValue: "",
    label: "Surname",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
    isHidden: checkField !== "surname",
  },
  {
    name: "given_name",
    initailValue: "",
    label: "Given Name",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
    isHidden: checkField !== "given_name",
  },
  {
    name: "other_name",
    initailValue: "",
    label: "Other Name",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
    isHidden: checkField !== "other_name",
  },
  {
    name: "date_of_birth",
    initailValue: "",
    label: "Date of Birth",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "date",
    applyDateLowerLimit: false,
    isHidden: checkField !== "dob",
  },
];
