import * as Yup from "yup";

export const NINValidations = Yup.object().shape({
  nin: Yup.string().required("NIN number is required"),
  branch: Yup.object().shape({
    id: Yup.string().required("Branch is required"),
  }),
  document_id: Yup.string().required("Document ID is required"),
});

export const PhoneValidations = Yup.object().shape({
  branch: Yup.object().shape({
    id: Yup.string().required("Branch is required"),
  }),
  phonenumber: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .matches(
      /^(256)[0-9]{9}$/,
      "Phone number must be a valid Ugandan phone number",
    )
    .required("Phone number is required"),
});
