import _ from "lodash";
import { IFormField } from "../../../../../utils/form_factory";

export const PhoneVerificationFields = (branchFetcher: any): IFormField[] => [
  {
    name: "branch",
    initailValue: "",
    label: "Branch",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "branch-field",
    dataFetcher: branchFetcher,
    selector: {
      value: (option: any) => option?.id,
      label: (option: any) => option?.name,
    },
    parseFilter: (id: string) => {
      return _({}).omitBy(_.isUndefined).value();
    },
    initailValueReadPath: "branch",
  },
  {
    name: "phonenumber",
    initailValue: "256",
    label: "Phone Number",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "phone",
    customFormat: "XXX-XXXXXXXXX",
  },
];
