import {
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import React, { FC, useState } from "react";
import { H6 } from "../../Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlexBox from "../../FlexBox";
import { ukoTheme } from "../../../theme";

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  bgColor: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, bgColor, ...other } = props;
  return <IconButton size="small" {...other} />;
})(({ theme, expand, bgColor }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  backgroundColor: bgColor,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CollapsibleSection: FC<CollapsibleSectionProps> = (props) => {
  const theme = ukoTheme();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      sx={[styles.container, { background: expanded ? "#EFEFEF" : "#FFFFFF" }]}
    >
      <FlexBox
        alignItems={"center"}
        justifyContent={"space-between"}
        borderBottom={expanded ? "1px solid #E5EAF2" : "none"}
      >
        <H6>{props.title}</H6>

        <ExpandMore
          bgColor={"#EFEFEF"}
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon sx={{ color: theme.palette.common.black }} />
        </ExpandMore>
      </FlexBox>

      <Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {props.children}
        </Collapse>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    border: "1px solid #E5EAF2",
    borderRadius: "8px",
    padding: "1rem",
  },
};

export default CollapsibleSection;
