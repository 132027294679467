import mFPassword from "./login/forgot-password.svg";
import mProfile from "./login/profile.svg";
import mLoader from "./others/Loader.svg";

// Login Slider
export const ProfileSVG = mProfile;
export const FPassword = mFPassword;

// others
export const LoaderSVG = mLoader;
