import React, { FC, useState } from "react";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import { deepDerefrencer } from "../../../utils/form_factory";
import { Small } from "../../Typography";
import { ukoTheme } from "../../../theme";

interface IFormMultiInputProps {
  name: string;
  label: any;
  formControl: any;
  isDisabled?: boolean;
  options: string[];
}

const MultiInputField: FC<IFormMultiInputProps | any> = (
  props: IFormMultiInputProps,
) => {
  const theme = ukoTheme();
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | null,
  ) => {
    if (value !== null) {
      const currentValues =
        deepDerefrencer(props.formControl.values, props.name) || [];
      const updatedValues = [...currentValues, value];
      props.formControl.setFieldValue(props.name, updatedValues);
    }
  };

  const handleInputKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      const newValue = inputValue.trim();
      const currentValues =
        deepDerefrencer(props.formControl.values, props.name) || [];
      const updatedValues = [...currentValues, newValue];
      props.formControl.setFieldValue(props.name, updatedValues);
      setInputValue("");
    }
  };

  const handleChipDelete = (value: string) => {
    const currentValues =
      deepDerefrencer(props.formControl.values, props.name) || [];
    const updatedValues = currentValues.filter((val: string) => val !== value);
    props.formControl.setFieldValue(props.name, updatedValues);
  };

  return (
    <Box>
      <Autocomplete
        freeSolo
        options={props?.options}
        value={inputValue}
        onChange={handleInputChange}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            onKeyDown={handleInputKeyPress}
            disabled={props.isDisabled}
            sx={[styles.customStyles(theme), styles.inputStyles(theme)]}
          />
        )}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginTop: 1 }}>
        {(props.formControl.values[props.name] || []).map(
          (value: string, index: number) => (
            <Chip
              key={index}
              label={value}
              onDelete={() => handleChipDelete(value)}
              sx={styles.chipStyles(theme)}
            />
          ),
        )}
      </Box>
      {Boolean(
        deepDerefrencer(props.formControl.touched, props.name) &&
          deepDerefrencer(props.formControl.errors, props.name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(props.formControl.errors, props.name)}
        </Small>
      )}
    </Box>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },
  }),
  inputStyles: (theme: any) => ({
    width: "100%",
  }),
  chipStyles: (theme: any) => ({
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  }),
};

export default MultiInputField;
