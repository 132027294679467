import { Navigate } from "react-router-dom";
import RouteGuard from "../guards/RouteGuard";
import AuthGuard from "../components/authentication/AuthGuard";
import GuestGuard from "../components/authentication/GuestGuard";
import DashboardLayout from "../components/Layouts/DashboardLayout";
import { LoginPortal } from "../pages/Authentication/LoginPortal";

import {
  CLIENT_TYPE_GROUP,
  CLIENT_TYPE_INDIVIDUAL,
  CLIENT_TYPE_INSTITUTION,
} from "../constants/client";
import {
  LOAN_STATUS_ACTIVE,
  LOAN_STATUS_DISBURSEMENT,
  LOAN_STATUS_APPLICATIONS,
  LOAN_STATUS_CLOSED,
  LOAN_STATUS_DECLINED,
  LOAN_STATUS_WRITTEN_OFF,
} from "../constants/loans";

import LazyLoader from "./routes-loader";
import { T_TYPE_DEPOSIT, T_TYPE_WITHDRAWAL } from "../constants/savings";
import ResetPassword from "../pages/Authentication/ResetPassword/ResetPassword";

import {
  T_LOAN_WRITE_OFF,
  T_TRANSACTION_REVERSAL,
  T_LOAN_DISBURSEMENT,
  T_LOAN_APPROVAL,
  T_SHARE_PURCHASE_REVERSAL,
  T_RECEIVABLE_REVERSAL,
  T_PAYABLE_REVERSAL,
  T_LOAN_TOPUP,
  T_ASSET_PURCHASE_REVERSAL,
} from "../constants/workflows";
import { ResetPasswordPage } from "../pages/Authentication/ForgotPassword/ResetPasswordPage";

const routes = [
  {
    path: "/",
    element: <Navigate to="dashboard" />,
  },
  {
    path: "login",
    element: (
      <GuestGuard>
        <LoginPortal />
      </GuestGuard>
    ),
  },
  {
    path: "register",
    element: (
      <GuestGuard>
        <LoginPortal />
      </GuestGuard>
    ),
  },
  {
    path: "forgot-password",
    element: (
      <GuestGuard>
        <LoginPortal />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password",
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    ),
  },
  {
    path: "reset-password-page",
    element: (
      <GuestGuard>
        <ResetPasswordPage />
      </GuestGuard>
    ),
  },
  {
    path: "change-password",
    element: (
      <AuthGuard>
        <LazyLoader.ChangePassword />
      </AuthGuard>
    ),
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <LazyLoader.DashboardDashboard />,
      },
    ],
  },
  {
    path: "forbidden",
    element: <LazyLoader.Error403 />,
  },
  {
    path: "*",
    element: <LazyLoader.Error404 />,
  },

  {
    path: "clients",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "individual",
        element: (
          <LazyLoader.Clients
            client_type={CLIENT_TYPE_INDIVIDUAL}
            title={"Individuals"}
          />
        ),
      },
      {
        path: "institution",
        element: (
          <LazyLoader.Clients
            client_type={CLIENT_TYPE_INSTITUTION}
            title={"Individuals"}
          />
        ),
      },

      {
        path: "group",
        element: (
          <LazyLoader.Clients
            client_type={CLIENT_TYPE_GROUP}
            title={"Individuals"}
          />
        ),
      },
      {
        path: "create-client",
        element: <LazyLoader.ClientForm />,
      },
      {
        path: "client-profile/:clientId",
        element: <LazyLoader.ClientProfile />,
      },
    ],
  },

  {
    path: "loans",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "active",
        element: <LazyLoader.Loans loanstatus={LOAN_STATUS_ACTIVE} />,
        requiresAuth: true,
      },
      {
        path: "disbursement",
        element: <LazyLoader.Loans loanstatus={LOAN_STATUS_DISBURSEMENT} />,
        requiresAuth: true,
      },
      {
        path: "applications",
        element: <LazyLoader.Loans loanstatus={LOAN_STATUS_APPLICATIONS} />,
        requiresAuth: true,
      },
      {
        path: "closed",
        element: <LazyLoader.Loans loanstatus={LOAN_STATUS_CLOSED} />,
        requiresAuth: true,
      },
      {
        path: "written-off-loans",
        element: <LazyLoader.Loans loanstatus={LOAN_STATUS_WRITTEN_OFF} />,
        requiresAuth: true,
      },
      {
        path: "declined",
        element: <LazyLoader.Loans loanstatus={LOAN_STATUS_DECLINED} />,
        requiresAuth: true,
      },
      {
        path: "calculator",
        element: <LazyLoader.LoanCalculator />,
        requiresAuth: true,
      },
      {
        path: "collateral",
        element: <LazyLoader.LoanCollateral />,
        requiresAuth: true,
      },
      {
        path: "loan-details/:loanId",
        element: <LazyLoader.LoanDetail />,
        requiresAuth: true,
      },
      {
        path: "create-loan",
        element: <LazyLoader.LoanForm />,
      },
    ],
  },

  {
    path: "savings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "deposit",
        element: <LazyLoader.RegisterDeposit />,
        requiresAuth: true,
      },
      {
        path: "withdraw",
        element: <LazyLoader.RegisterWithdraw />,
        requiresAuth: true,
      },
      {
        path: "deposit-transactions",
        element: <LazyLoader.Transactions t_type={T_TYPE_DEPOSIT} />,
        requiresAuth: true,
      },
      {
        path: "withdraw-transactions",
        element: <LazyLoader.Transactions t_type={T_TYPE_WITHDRAWAL} />,
        requiresAuth: true,
      },
      {
        path: "disburse-interest",
        element: <LazyLoader.DisburseInterest />,
        requiresAuth: true,
      },
    ],
  },

  {
    path: "workflow-requests",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "transaction-reversal",
        element: <LazyLoader.WorkflowRequest t_type={T_TRANSACTION_REVERSAL} />,
        requiresAuth: true,
      },
      {
        path: "loan-write-off",
        element: <LazyLoader.WorkflowRequest t_type={T_LOAN_WRITE_OFF} />,
        requiresAuth: true,
      },
      {
        path: "loan-disbursement",
        element: <LazyLoader.WorkflowRequest t_type={T_LOAN_DISBURSEMENT} />,
        requiresAuth: true,
      },
      {
        path: "loan-approval",
        element: <LazyLoader.WorkflowRequest t_type={T_LOAN_APPROVAL} />,
        requiresAuth: true,
      },
      {
        path: "loan-topup",
        element: <LazyLoader.WorkflowRequest t_type={T_LOAN_TOPUP} />,
        requiresAuth: true,
      },
      {
        path: "share_purchase_reversal",
        element: (
          <LazyLoader.WorkflowRequest t_type={T_SHARE_PURCHASE_REVERSAL} />
        ),
        requiresAuth: true,
      },
      {
        path: "receivable_reversal",
        element: <LazyLoader.WorkflowRequest t_type={T_RECEIVABLE_REVERSAL} />,
        requiresAuth: true,
      },
      {
        path: "payable_reversal",
        element: <LazyLoader.WorkflowRequest t_type={T_PAYABLE_REVERSAL} />,
        requiresAuth: true,
      },
      {
        path: "asset-purchase-reversal",
        element: (
          <LazyLoader.WorkflowRequest t_type={T_ASSET_PURCHASE_REVERSAL} />
        ),
        requiresAuth: true,
      },
    ],
  },

  {
    path: "shares",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "register",
        element: <LazyLoader.ShareRegister />,
        requiresAuth: true,
      },
      {
        path: "share-purchase",
        element: <LazyLoader.PurchaseShares />,
      },
      {
        path: "share-sell",
        element: <LazyLoader.SellShares />,
      },
      {
        path: "share-transfer",
        element: <LazyLoader.TransferShares />,
        requiresAuth: true,
      },
      {
        path: "purchase-transactions",
        element: <LazyLoader.SharePurchases />,
        requiresAuth: true,
      },
      {
        path: "transfer-transactions",
        element: <LazyLoader.ShareTransfers />,
        requiresAuth: true,
      },
      {
        path: "dividends",
        element: <LazyLoader.Dividends />,
        requiresAuth: true,
      },
      {
        path: "dividend-payout",
        element: <LazyLoader.DividendPayout />,
        requiresAuth: true,
      },
      {
        path: "dividend-payout/create",
        element: <LazyLoader.DividendPayoutCreate />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "accounting",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "chart-of-accounts",
        element: <LazyLoader.Accounting />,
        requiresAuth: true,
      },
      {
        path: "transaction-accounts",
        element: <LazyLoader.TransactionAccounts />,
        requiresAuth: true,
      },
      {
        path: "cash-transfers",
        element: <LazyLoader.CashTransfers />,
        requiresAuth: true,
      },
      {
        path: "suppliers",
        element: <LazyLoader.Supplier />,
        requiresAuth: true,
      },
      {
        path: "payables",
        element: <LazyLoader.Payables />,
        requiresAuth: true,
      },
      {
        path: "payables/creditors",
        element: <LazyLoader.Creditors />,
        requiresAuth: true,
      },
      {
        path: "receivables",
        element: <LazyLoader.Receivables />,
        requiresAuth: true,
      },
      {
        path: "receivables/debtors",
        element: <LazyLoader.Debtors />,
        requiresAuth: true,
      },
      {
        path: "staff-till-sheet",
        element: <LazyLoader.StaffTillSheet />,
        requiresAuth: true,
      },
      {
        path: "cash-account-details/:id",
        element: <LazyLoader.CashAccountDetails />,
      },
      {
        path: "bank-account-details/:id",
        element: <LazyLoader.BankAccountDetails />,
      },
      {
        path: "safe-account-details/:id",
        element: <LazyLoader.SafeAccountDetails />,
      },
      {
        path: "bank-account-details/:id",
        element: <LazyLoader.BankAccountDetails />,
      },
    ],
  },

  {
    path: "sms-banking",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "settings",
        element: <LazyLoader.SMSSettings />,
        requiresAuth: true,
      },
      {
        path: "settings/create-auto-type",
        element: <LazyLoader.SMSCreateAutoType />,
        requiresAuth: true,
      },
      {
        path: "outbox",
        element: <LazyLoader.SMSOutBox />,
        requiresAuth: true,
      },
      {
        path: "outbox/compose",
        element: <LazyLoader.SMSCreateOutBox />,
        requiresAuth: true,
      },
      {
        path: "outbox/bulk/compose",
        element: <LazyLoader.SMSCreateBulkOutBox />,
        requiresAuth: true,
      },
    ],
  },

  {
    path: "data-importer",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "import-clients",
        element: <LazyLoader.ImportClients />,
        requiresAuth: true,
      },
      {
        path: "import-clients/records/:batchId",
        element: <LazyLoader.ImportClientRecords />,
      },
      {
        path: "import-loans",
        element: <LazyLoader.ImportLoans />,
        requiresAuth: true,
      },
      {
        path: "import-loan-payments",
        element: <LazyLoader.ImportLoanPayments />,
        requiresAuth: true,
      },
      {
        path: "import-loans/records/:batchId",
        element: <LazyLoader.ImportLoanRecords />,
      },
      {
        path: "import-loan-payments/records/:batchId",
        element: <LazyLoader.ImportLoanPaymentRecords />,
      },
      {
        path: "import-savings-transactions",
        element: <LazyLoader.ImportSavingTransactions />,
        requiresAuth: true,
      },
      {
        path: "import-savings-transactions/records/:batchId",
        element: <LazyLoader.ImportSavingTransactionRecords />,
      },
      {
        path: "import-shares",
        element: <LazyLoader.ImportShares />,
        requiresAuth: true,
      },
      {
        path: "import-shares/records/:batchId",
        element: <LazyLoader.ImportSharesRecords />,
      },
      {
        path: "import-client-accounts",
        element: <LazyLoader.ImportClientAccounts />,
        requiresAuth: true,
      },
      {
        path: "import-client-accounts/records/:batchId",
        element: <LazyLoader.ImportClientAccountsRecords />,
      },
      {
        path: "import-coa",
        element: <LazyLoader.ImportCoa />,
        requiresAuth: true,
      },
      {
        path: "import-coa/records/:batchId",
        element: <LazyLoader.ImportCoaRecords />,
      },
    ],
  },

  {
    path: "reports",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "balance-sheet",
        element: <LazyLoader.BalanceSheet />,
        requiresAuth: true,
      },
      {
        path: "cashflow-statement",
        element: <LazyLoader.CashflowStatement />,
        requiresAuth: true,
      },
      {
        path: "trial-balance",
        element: <LazyLoader.TrialBalance />,
        requiresAuth: true,
      },
      {
        path: "income-statement",
        element: <LazyLoader.IncomeStatement />,
        requiresAuth: true,
      },
      {
        path: "ageing-report",
        element: <LazyLoader.AgeingReport />,
        requiresAuth: true,
      },
      {
        path: "loan-provision-report",
        element: <LazyLoader.LoanProvisionReport />,
        requiresAuth: true,
      },
      {
        path: "loan-arrears-report",
        element: <LazyLoader.LoanArrearsReport />,
        requiresAuth: true,
      },
      {
        path: "loan-status-report",
        element: <LazyLoader.LoanStatusReport />,
        requiresAuth: true,
      },
      {
        path: "loan-disbursement-report",
        element: <LazyLoader.LoanDisbursementReport />,
        requiresAuth: true,
      },
      {
        path: "portfolio-at-risk-report",
        element: <LazyLoader.PortfolioAtRiskReport />,
        requiresAuth: true,
      },
      {
        path: "loan-repayment-report",
        element: <LazyLoader.LoanRepaymentReport />,
        requiresAuth: true,
      },
      {
        path: "client-report",
        element: <LazyLoader.ClientReport />,
        requiresAuth: true,
      },
      {
        path: "membership-schedule",
        element: <LazyLoader.MembershipSchedule />,
        requiresAuth: true,
      },
      {
        path: "expenses-report",
        element: <LazyLoader.ExpensesReport />,
        requiresAuth: true,
      },
      {
        path: "fees-report",
        element: <LazyLoader.FeesReport />,
        requiresAuth: true,
      },
      {
        path: "loan-officer-report",
        element: <LazyLoader.LoanOfficerReport />,
        requiresAuth: true,
      },
      {
        path: "loan-officer-summary-report",
        element: <LazyLoader.LoanOfficerSummaryReport />,
        requiresAuth: true,
      },
      {
        path: "savings-report",
        element: <LazyLoader.SavingsReport />,
        requiresAuth: true,
      },
      {
        path: "snapshot-report",
        element: <LazyLoader.SnapshotReport />,
        requiresAuth: true,
      },
      {
        path: "summary-collection-report",
        element: <LazyLoader.SummaryCollection />,
        requiresAuth: true,
      },
      {
        path: "savings-officer-report",
        element: <LazyLoader.SavingsOfficerReport />,
        requiresAuth: true,
      },
      {
        path: "cumulative-savings-report",
        element: <LazyLoader.CumulativeSavingsReport />,
        requiresAuth: true,
      },
      {
        path: "par-aging-report",
        element: <LazyLoader.ParAgingReport />,
        requiresAuth: true,
      },
      {
        path: "loan-book-report",
        element: <LazyLoader.LoanBookReport />,
        requiresAuth: true,
      },
      {
        path: "summary-collection-report",
        element: <LazyLoader.SummaryCollection />,
        requiresAuth: true,
      },
      {
        path: "non-physical-asset-report",
        element: <LazyLoader.NonPhysicalAssetReport />,
        requiresAuth: true,
      },
      {
        path: "physical-asset-report",
        element: <LazyLoader.PhysicalAssetReport />,
        requiresAuth: true,
      },
      {
        path: "physical-asset-sale-report",
        element: <LazyLoader.PhysicalAssetSaleReport />,
        requiresAuth: true,
      },
      {
        path: "expected-loan-repayment-report",
        element: <LazyLoader.ExpectedLoanRepaymentReport />,
        requiresAuth: true,
      },
      {
        path: "loan-repayment-transaction-report",
        element: <LazyLoader.LoanRepaymentTransaction />,
        requiresAuth: true,
      },
      {
        path: "expected-loan-repayment-report",
        element: <LazyLoader.ExpectedLoanRepaymentReport />,
        requiresAuth: true,
      },
    ],
  },

  {
    path: "settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "bank-settings",
        element: <LazyLoader.BankSettings />,
        requiresAuth: true,
      },
      {
        path: "branches",
        element: <LazyLoader.Branches />,
        requiresAuth: true,
      },
      {
        path: "workflows",
        element: <LazyLoader.Workflow />,
        requiresAuth: true,
      },
      {
        path: "workflows/levels",
        element: <LazyLoader.WorkflowLevels />,
        requiresAuth: true,
      },
      {
        path: "create-role",
        element: <LazyLoader.CreateRole />,
        requiresAuth: true,
      },
      {
        path: "roles-and-permissions",
        element: <LazyLoader.RolesAndPermissions />,
        requiresAuth: true,
      },
      {
        path: "staff",
        element: <LazyLoader.Staff />,
        requiresAuth: true,
      },
      {
        path: "staff/create",
        element: <LazyLoader.StaffForm />,
        requiresAuth: true,
      },
      {
        path: "staff/details/:staffId",
        element: <LazyLoader.StaffDetails />,
        requiresAuth: true,
      },
      {
        path: "fees-settings",
        element: <LazyLoader.FeesSettings />,
        requiresAuth: true,
      },
      {
        path: "loan-settings",
        element: <LazyLoader.LoanSettings />,
        requiresAuth: true,
      },
      {
        path: "loan-settings/product",
        element: <LazyLoader.LoanProductSettings />,
        requiresAuth: true,
      },
      {
        path: "loan-settings/create/product",
        element: <LazyLoader.LoanProductForm />,
        requiresAuth: true,
      },
      {
        path: "savings-settings",
        element: <LazyLoader.SavingsSettings />,
        requiresAuth: true,
      },
      {
        path: "savings-settings/create/product",
        element: <LazyLoader.SavingsProductForm />,
        requiresAuth: true,
      },
      {
        path: "shares-settings",
        element: <LazyLoader.SharesSettings />,
        requiresAuth: true,
      },
      {
        path: "working-hours",
        element: <LazyLoader.WorkingHours />,
        requiresAuth: true,
      },
      {
        path: "general-settings",
        element: <LazyLoader.GeneralSettings />,
        requiresAuth: true,
      },
    ],
  },

  {
    path: "all-banks",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
          <RouteGuard requiredPermissions={[]}>
            <LazyLoader.Institution />
          </RouteGuard>
        ),
        requiresAuth: true,
      },
      {
        path: "create-new-bank",
        element: (
          <RouteGuard requiredPermissions={[]}>
            <LazyLoader.InstitutionCreate />
          </RouteGuard>
        ),
        requiresAuth: true,
      },
      {
        path: "institution-details/:id",
        element: <LazyLoader.InstitutionDetails />,
        requiresAuth: true,
      },
    ],
  },

  {
    path: "all-banks-admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/all-banks-admin",
        element: <LazyLoader.InstitutionAdmin />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "master-admin",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/master-admin",
        element: <LazyLoader.MasterAdmin />,
        requiresAuth: true,
      },
      {
        path: "/master-admin/add-master-admin",
        element: <LazyLoader.AddMasterAdmin />,
        requiresAuth: true,
      },
      {
        path: "/master-admin/add-master-admin/:masterAdminId",
        element: <LazyLoader.MasterAdminDetails />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "account",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "profile",
        element: <LazyLoader.UserProfile />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "assets",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/assets/register-physcial",
        element: <LazyLoader.RegisterAssets />,
        requiresAuth: true,
      },
      {
        path: "/assets/physical",
        element: <LazyLoader.PhyscialAssets />,
        requiresAuth: true,
      },
      {
        path: "/assets/physical/:Id",
        element: <LazyLoader.PhyscialAssetsDetails />,
        requiresAuth: true,
      },
      {
        path: "/assets/non-physcial",
        element: <LazyLoader.NonPhyscialAssets />,
        requiresAuth: true,
      },
      {
        path: "/assets/register-non-physical",
        element: <LazyLoader.RegisterNonPhyscialAssets />,
        requiresAuth: true,
      },
      {
        path: "/assets/non-physical/:Id",
        element: <LazyLoader.NonPhyscialAssetsDetails />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "trash",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "deleted-clients-individual",
        element: (
          <LazyLoader.Trash
            client_type={CLIENT_TYPE_INDIVIDUAL}
            title={"Individuals"}
          />
        ),
      },
      {
        path: "deleted-clients-institution",
        element: (
          <LazyLoader.Trash
            client_type={CLIENT_TYPE_INSTITUTION}
            title={"Individuals"}
          />
        ),
      },

      {
        path: "deleted-clients-group",
        element: (
          <LazyLoader.Trash
            client_type={CLIENT_TYPE_GROUP}
            title={"Individuals"}
          />
        ),
      },
      {
        path: "deleted-loan-products",
        element: <LazyLoader.LoanProductsTrash />,
        requiresAuth: true,
      },
      {
        path: "deleted-savings-products",
        element: <LazyLoader.SavingsProductsTrash />,
        requiresAuth: true,
      },
      {
        path: "deleted-accounts",
        element: <LazyLoader.AccountTrash />,
        requiresAuth: true,
      },
    ],
  },
  {
    path: "kyc-settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <LazyLoader.KYCSettings />,
      },
    ],
  },
  {
    path: "kyc",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "services",
        element: <LazyLoader.KycService />,
        requiresAuth: true,
      },
      {
        path: "checks",
        element: <LazyLoader.KycCheck />,
        requiresAuth: true,
      },
    ],
  },
];

export default routes;
