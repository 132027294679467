import { Box } from "@mui/material";
import React, { FC } from "react";
import { LoaderSVG } from "../../../assets/svg/SvgIcons";
import { H4, Paragraph } from "../../Typography";

interface KYCLoaderProps {
  verificationType?: string;
}

export const KYCLoader: FC<KYCLoaderProps> = ({
  verificationType = "your submission",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img style={{ marginTop: 100 }} src={LoaderSVG} alt="loader" />
      <H4 sx={{ marginTop: 2, fontSize: 25, fontWeight: 500 }}>
        Fetching your results
      </H4>
      <Paragraph sx={{ maxWidth: 400, textAlign: "center" }}>
        We are checking the {verificationType} against the details you have
        provided for a match...
      </Paragraph>
    </Box>
  );
};

export default KYCLoader;
