import { Box, Drawer, LinearProgress } from "@mui/material";
import { FC, useEffect } from "react";
import { useDrawerContext } from "../../contexts/DrawerContext";
import { ukoTheme } from "../../theme";
import { use } from "i18next";
import { cleanUpGlobalBranchState } from "../../constants/branch-configs";

export type Anchor = "top" | "left" | "bottom" | "right";

export interface IDialogProps {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  minWidth?: number;
  selectedDrawerId?: string;
  maxWidth?: number;
  anchor: Anchor;
  onClose: () => void;
}

const DrawerBox: FC<IDialogProps> = ({
  children,
  onClose,
  title,
  maxWidth,
  minWidth,
  selectedDrawerId,
  anchor,
}) => {
  const theme = ukoTheme();
  const { showDrawer, setShowDrawer, loadingDrawerContent, drawerId } =
    useDrawerContext();

  if (!showDrawer) return null;

  if (drawerId !== undefined) {
    if (drawerId !== selectedDrawerId) return null;
  }

  if (showDrawer[anchor].valueOf() && drawerId) {
    cleanUpGlobalBranchState(true);
  }

  const toggleDrawer = (anchor: Anchor, open: boolean) => {
    cleanUpGlobalBranchState(open);
    setShowDrawer({ ...showDrawer, [anchor]: open });
  };

  const handleBackgroundClick = (
    event: React.MouseEvent,
    anchor: Anchor,
    state: boolean,
  ) => {
    onClose();
    toggleDrawer(anchor, state);
  };

  return (
    <>
      <Drawer
        anchor={anchor}
        open={showDrawer[anchor]}
        onClose={(e: React.MouseEvent) => {
          handleBackgroundClick(e, anchor, false);
        }}
      >
        {loadingDrawerContent && (
          <Box sx={{ width: "100%", zIndex: 1200 }}>
            <LinearProgress />
          </Box>
        )}
        <Box
          sx={{
            minWidth: `${minWidth}px` || "600px",
            maxWidth: `${maxWidth}px` || "0px",
            height: "100%",
            [theme.breakpoints.down("sm")]: {
              minWidth: "100%",
            },
            [theme.breakpoints.down("midsm")]: {
              minWidth: "350px",
              maxWidth: "350px",
            },
            [theme.breakpoints.between(400, 600)]: {
              minWidth: "400px",
            },
          }}
        >
          {children}
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerBox;
