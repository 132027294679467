import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "./constants";
import toast from "react-hot-toast";
import { H6 } from "../components/Typography";

const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.withCredentials = true;

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${atob(accessToken)}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const recursiveSetNull = (obj: any) => {
      if (!obj) return;

      Object.keys(obj).forEach((key) => {
        if (obj[key] === "") {
          obj[key] = null;
        } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          recursiveSetNull(obj[key]);
        }
      });
    };

    // intercept request and add branch id to params if method is get
    if (config.method === "get") {
      const branch = sessionStorage.getItem("branch");
      if (branch) {
        const jsonBranch: any = JSON.parse(branch);
        config.params = {
          ...config.params,
          branch: jsonBranch.id,
        };
      }
    }

    if (config.data) {
      recursiveSetNull(config.data);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// interceptor response and if error is 401, redirect to login else log error using toast
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }

    // if status is in range of internal server error, log error using toast
    if (error.response?.status >= 500) {
      toast.error(error.response.statusText);
      return Promise.reject(new Error("Internal Server Error"));
    }

    // if action is forbidden with code 403, log error using toast
    if (error.response?.status === 403) {
      toast.error(
        () => {
          return <H6>You don't permissions for this action</H6>;
        },
        {
          duration: 5000,
          position: "bottom-right",
          icon: "🔒",
          iconTheme: {
            primary: "#1d2438",
            secondary: "#FFFAEE",
          },
        },
      );
    }
    return Promise.reject(error);
  },
);

export default instance;
