import React, { FC } from "react";
import { H6, Span } from "../../../Typography";
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlexBox from "../../../FlexBox";
import { ukoTheme } from "../../../../theme";
import CollapsibleSection from "../CollapsibleSection";
import DownloadIcon from "@mui/icons-material/Download";

interface ResultsCreditReportProps {
  data: any;
}

const ResultsCreditReport: FC<ResultsCreditReportProps> = (props) => {
  const theme = ukoTheme();

  return (
    <Box sx={{ paddingBottom: 5 }}>
      <Box sx={styles.container} marginBottom={2}>
        <FlexBox gap={2} alignItems={"flex-start"}>
          <Box sx={styles.iconCircle}>
            <AccountCircleIcon
              color="primary"
              sx={{ height: "3.8rem", width: "3.8rem" }}
            />
          </Box>
          <FlexBox flexDirection={"column"} gap={0.8}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Katungi Bernard
            </H6>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FlexBox flexDirection={"column"} gap={0.8}>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>Male:</Span> 34
                    years
                  </Span>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>
                      Date of Birth:
                    </Span>{" "}
                    01/01/1993
                  </Span>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>Tel:</Span>{" "}
                    +256782750413
                  </Span>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>Address:</Span>
                    Laroo, Agwee Gulu, Central Uganda
                  </Span>
                </FlexBox>
              </Grid>
              <Grid item xs={6}>
                <FlexBox flexDirection={"column"} gap={0.8}>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>
                      Country of Issue:
                    </Span>{" "}
                    Uganda
                  </Span>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>Nationality:</Span>{" "}
                    Ugandan
                  </Span>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>
                      Marital Status:{" "}
                    </Span>{" "}
                    Married
                  </Span>
                  <Span sx={styles.labelValue}>
                    <Span color={theme.palette.common.black}>Email: </Span>{" "}
                    bernardkatungi@gmail.com
                  </Span>
                </FlexBox>
              </Grid>
            </Grid>
          </FlexBox>
        </FlexBox>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Physical Address History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Address
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Ownership
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Period at Address
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Postal Address History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Address
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Contact History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Phone Number
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Emails
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Email
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Shareholder Details">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Business Name
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Shareholder type
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Shareholder Category
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Shareholder Percentage
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Employment History">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  SNo
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Employer Name
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Occupation
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Employment Date
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Income Band
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Scoring Details">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Evaluation Period
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Current Score
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Current score Band
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Current Score Rating
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Probability of Default percent
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Likelihood to default
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Score Explanation
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>

              <Grid item xs={12}>
                <H6>Score History</H6>

                <TableContainer component={Paper} elevation={0}>
                  <Table aria-label="simple table">
                    <TableHead sx={{ border: "1px solid #E5EAF2" }}>
                      <TableRow>
                        <TableCell>Period</TableCell>
                        <TableCell>Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow sx={{ border: "1px solid #E5EAF2" }}>
                        <TableCell component="th" scope="row">
                          2024-6
                        </TableCell>
                        <TableCell>600</TableCell>
                      </TableRow>
                      <TableRow sx={{ border: "1px solid #E5EAF2" }}>
                        <TableCell component="th" scope="row">
                          2024-6
                        </TableCell>
                        <TableCell>600</TableCell>
                      </TableRow>
                      <TableRow sx={{ border: "1px solid #E5EAF2" }}>
                        <TableCell component="th" scope="row">
                          2024-6
                        </TableCell>
                        <TableCell>600</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Applications Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <H6>Total Credit Applications</H6>
                <Span sx={styles.vlabelValue}>2</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Submitted Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 20,000,000</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Pending Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 20,000,000</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Approved Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 20,000,000</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Rejected Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 20,000,000</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Cancelled Applications</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Number of Applications
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>UGX 20,000,000</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Accounts Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Credit Accounts
                </H6>
                <Span sx={styles.vlabelValue}>2</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Open Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Closed Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Adverse Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Worst Active days in Arrears
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6 marginBottom={1}>Credit Accounts</H6>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Balance
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount Overdue
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Accounts Classification Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Institution Type
                </H6>
                <Span sx={styles.vlabelValue}>CB Commercial Bank</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  No of Accounts
                </H6>
                <Span sx={styles.vlabelValue}>2</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Loan Amount
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Loan Balance
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Collateral Material Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Collateral Material
                </H6>
                <Span sx={styles.vlabelValue}>1</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Collateral Material</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Items
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Value of Items
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Collateral Guarantor Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Collateral Guarantors
                </H6>
                <Span sx={styles.vlabelValue}>1</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Collateral Guarantor</H6>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Guaranteed Accounts
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount Guaranteed
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Balance on Guaranteed Accounts
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Bounced Cheques Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Bounced Cheques
                </H6>
                <Span sx={styles.vlabelValue}>1</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Bounced Cheques</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Bounced Cheques
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Value of Bounced Cheques{" "}
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Financial Fraud Summary">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Financial Malpractice
                </H6>
                <Span sx={styles.vlabelValue}>1</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <Grid container>
                    <Grid item xs={12}>
                      <H6>Bounced Cheques</H6>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        No of Bounced Cheques
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={6}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loss Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <Button variant="outlined" color="primary" sx={{ width: "100%" }}>
          <DownloadIcon sx={{ marginRight: 1 }} />
          Download Score
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#EFEFEF",
    borderRadius: "0.5rem",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#000000",
  },
  labelValue: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#5F6368",
    display: "block",
  },
  vlabelText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#000000",
  },
  vlabelValue: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5F6368",
    display: "block",
  },
  iconCircle: {
    height: "4rem",
    width: "4rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteCard: {
    height: "100%",
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "0.5rem",
    border: "1px solid #E5EAF2",
  },
};

export default ResultsCreditReport;
