import instance from "../../../../api";
export const BranchService = {
  async getBranch(filters: Object) {
    return instance
      .get("branch/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getBranchDetails(id: string) {
    return instance.get(`branch/${id}/`).then((response) => response.data);
  },

  async createBranch(payload: Object) {
    return instance.post("branch/", payload).then((response) => response.data);
  },

  async updateBranch(payload: Object, id: string) {
    return instance
      .patch(`branch/${id}/`, payload)
      .then((response) => response.data);
  },

  async setUpClic(payload: Object, id: string) {
    return instance
      .post(`branch/${id}/provider/clic/`, payload)
      .then((response) => response.data);
  },

  async clicDashboardLink(payload: Object, id: string) {
    return instance
      .post(`branch/${id}/provider/access-link/`, payload)
      .then((response) => response.data);
  },

  async deleteBranch(id: string) {
    return instance.delete(`branch/${id}/`).then((response) => response.data);
  },

  async getTags(filters: Object) {
    return instance
      .get("tags/", {
        params: filters,
      })
      .then((response) => response.data);
  },
};
