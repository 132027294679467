import instance from "../../../../../api";

export const KycCheckService = {
  async kycCheckPhoneService(data: any) {
    return instance
      .post(`kyc-check/verify-phonenumber/`, data)
      .then((response) => response.data);
  },
  async kycCheckValidIdService(data: any) {
    return instance
      .post(`kyc-check/validate-personal-info/`, data)
      .then((response) => response.data);
  },
  async kycCheckNINService(data: any) {
    return instance
      .post(`kyc-check/verify-nin/`, data)
      .then((response) => response.data);
  },
  async kycCheckCreditScoreService(data: any) {
    return instance
      .post(`kyc-check/credit-score/`, data)
      .then((response) => response.data);
  },
  async kycCheckCreditReportService(data: any) {
    return instance
      .post(`kyc-check/credit-report/`, data)
      .then((response) => response.data);
  },
  async getIdentTypesService() {
    return instance
      .get(`/kyc-check/identification-types/`)
      .then((response) => response.data);
  },
};
