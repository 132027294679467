import React, { FC } from "react";
import { H6, Span } from "../../../Typography";
import { Box, Button, Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FlexBox from "../../../FlexBox";
import { ukoTheme } from "../../../../theme";
import CollapsibleSection from "../CollapsibleSection";
import DownloadIcon from "@mui/icons-material/Download";
import { he } from "date-fns/locale";

interface ResultsCreditScoreProps {
  data: any;
}

const ResultsCreditScore: FC<ResultsCreditScoreProps> = (props) => {
  const theme = ukoTheme();

  return (
    <Box sx={{ paddingBottom: 5 }}>
      <Box sx={styles.container} marginBottom={2}>
        <FlexBox gap={2} alignItems={"center"}>
          <Box sx={styles.iconCircle}>
            <AccountCircleIcon
              color="primary"
              sx={{ height: "3.8rem", width: "3.8rem" }}
            />
          </Box>
          <FlexBox flexDirection={"column"} gap={0.8}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Katungi Bernard
            </H6>
            <Span sx={styles.labelValue}>
              <Span color={theme.palette.common.black}>Male:</Span> 34 years
            </Span>
            <Span sx={styles.labelValue}>
              <Span color={theme.palette.common.black}>Date of Birth:</Span>{" "}
              01/01/1993
            </Span>
            <Span sx={styles.labelValue}>
              <Span color={theme.palette.common.black}>Phone Number:</Span>{" "}
              +256782750413
            </Span>
          </FlexBox>
        </FlexBox>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Scoring">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Period
                </H6>
                <Span sx={styles.vlabelValue}>John Doe</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Score
                </H6>
                <Span sx={styles.vlabelValue}>01/01/1994</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Band
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Rating
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Probability of Default percent
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Likelihood to default
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Previous Score
                </H6>
                <Span sx={styles.vlabelValue}>UGX 1,000</Span>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Applications">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Total Applications (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Applications_12 Months (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Pending Applications (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Approved Applications (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Rejected Applications (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Cancelled Applications (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Credit Accounts">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container rowSpacing={2.5}>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Total Accounts_12 months
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Open Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Open Accounts_ 12 months
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Closed Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Closed Accounts_ 12 months
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Adverse Accounts
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Adverse Accounts_ 12 months
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Worst Active days in Arrears
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>
              <Grid item xs={6}>
                <H6 marginBottom={0} sx={styles.vlabelText}>
                  Worst Active days in Arrears_ 12 months
                </H6>
                <Span sx={styles.vlabelValue}>0</Span>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <H6>Total Value</H6>
                  <Grid container>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Amount
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Balance
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount Overdue
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={styles.whiteCard}>
                  <H6>Value in 12 Months</H6>
                  <Grid container>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Amount_12 months
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Payment_12 months
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Loan Balance_12 months
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                    <Grid item xs={4}>
                      <H6 marginBottom={0} sx={styles.vlabelText}>
                        Amount Overdue_12 months{" "}
                      </H6>
                      <Span sx={styles.vlabelValue}>0</Span>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Collateral Material">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Collateral Material (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Collateral Guarantor">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <H6>Accounts: 0</H6>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Loan Amount
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Loan Balance
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Bounced Cheques">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Bounced Cheques (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <CollapsibleSection title="Financial Fraud">
          <Box
            sx={[
              styles.container,
              {
                marginX: "-1rem",
                marginBottom: "-1rem",
                transition: "all 0.3s",
              },
            ]}
          >
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Box sx={styles.whiteCard}>
                  <H6 marginBottom={0} sx={styles.vlabelText}>
                    Financial Fraud Cases (0)
                  </H6>
                  <Span sx={styles.vlabelValue}>UGX 0</Span>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CollapsibleSection>
      </Box>

      <Box marginBottom={2}>
        <Button variant="outlined" color="primary" sx={{ width: "100%" }}>
          <DownloadIcon sx={{ marginRight: 1 }} />
          Download Score
        </Button>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#EFEFEF",
    borderRadius: "0.5rem",
  },
  labelText: {
    fontSize: "1rem",
    fontWeight: 600,
    color: "#000000",
  },
  labelValue: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#5F6368",
    display: "block",
  },
  vlabelText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#000000",
  },
  vlabelValue: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5F6368",
    display: "block",
  },
  iconCircle: {
    height: "4rem",
    width: "4rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whiteCard: {
    height: "100%",
    padding: "1rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "0.5rem",
    border: "1px solid #E5EAF2",
  },
};

export default ResultsCreditScore;
