import React, { FC, useState } from "react";
import { deepDerefrencer, IFormField } from "../../../../utils/form_factory";
import { Box, Button } from "@mui/material";
import Chip from "@mui/material/Chip";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import CloseIcon from "@mui/icons-material/Close";

import { useDrawerContext } from "../../../../contexts/DrawerContext";
import DrawerBox from "../../DrawerBox";
import { H4 } from "../../../Typography";
import VerificationButtonForm from "./VerificationButtonForm";
import KYCLoader from "../KYCLoader";
import KYCResults from "../KYCResults";
import { KycCheckService } from "./services/KYCCheck.service";
import toast from "react-hot-toast";

export enum VerificationStatus {
  VERIFIED = "Verified",
  UNVERIFIED = "Unverified",
}

export enum VerificationType {
  VERIFYPHONE = "VerifyPhone",
  VERIFYNIN = "VerifyNIN",
  CHECKCREDITSCORE = "CheckCreditScore",
  CHECKCREDITREPORT = "CheckCreditReport",
  VERIFYID = "VerifyID",
}

export enum PhoneType {
  PRIMARY = "primarycellphone",
  SECONDARY = "secondarycellphone",
}

export const getVerificationStatus = (status: string) => {
  switch (status) {
    case "0":
      return undefined;
    case "1":
      return VerificationStatus.VERIFIED;
    case "3":
      return VerificationStatus.UNVERIFIED;
    default:
      return undefined;
  }
};

export interface IVerificationButtonProps {
  formField: IFormField;
  formInstance: any;
  verificationType: VerificationType;
  verificationStatus?: VerificationStatus | undefined;
  phoneType?: PhoneType;
  client?: any;
  branch?: any;
}

const VerificationButton: FC<IVerificationButtonProps> = (props) => {
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [verifying, setVerifying] = useState<boolean>(false);
  const [checkResults, setCheckResults] = useState<any>(null);
  const [verificationStatus, setVerificationStatus] = useState<
    VerificationStatus | undefined
  >(props.verificationStatus);
  const [retryParams, setRetryParams] = useState<any>(null);

  const isValid = () => {
    return (
      deepDerefrencer(props?.formInstance?.values, props.formField.name) &&
      !deepDerefrencer(props?.formInstance?.errors, props.formField.name)
    );
  };

  const handleOpenDrawer = async () => {
    setDrawerId(`${props.verificationType}-${props.formField.name}`);
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setVerifying(false);
    setCheckResults(null);
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  const handleVerifyPhone = async (data: any) => {
    setVerifying(true);
    try {
      const res: any = await KycCheckService.kycCheckPhoneService(data);
      toast.success(res?.message);
      setCheckResults(res?.result);

      if (res?.result?.result?.status === "SUCCESSFUL") {
        handleUpdateBtnStatus(1);
        setVerificationStatus(VerificationStatus.VERIFIED);
      }

      if (res?.result?.result?.status === "FAILED") {
        handleUpdateBtnStatus(0);
        setVerificationStatus(VerificationStatus.UNVERIFIED);
      }
    } catch (error) {
      toast.error("Failed to make verification request");
    } finally {
      setVerifying(false);
    }
  };

  const handleValidId = async (data: any) => {
    setVerifying(true);
    try {
      const res: any = await KycCheckService.kycCheckValidIdService(data);
      toast.success(res?.message);
      setCheckResults(res?.result);
    } catch (error) {
      toast.error("Failed to make verification request");
    } finally {
      setVerifying(false);
    }
  };

  const getVerificationStatus = () => {
    switch (props.verificationType) {
      case VerificationType.VERIFYPHONE:
        if (props.phoneType === PhoneType.PRIMARY) {
          const value = deepDerefrencer(
            props?.formInstance?.values,
            "user_details.primary_phone_validity_status",
          );

          if (value === 1) return VerificationStatus.VERIFIED;
          if (value === 0) return VerificationStatus.UNVERIFIED;
          return undefined;
        } else {
          const value = deepDerefrencer(
            props?.formInstance?.values,
            "user_details.secondary_phone_validity_status",
          );

          if (value === 1) return VerificationStatus.VERIFIED;
          if (value === 0) return VerificationStatus.UNVERIFIED;
          return undefined;
        }
      case VerificationType.VERIFYID:
        const value = deepDerefrencer(
          props?.formInstance?.values,
          "user_details.nin_validity_status",
        );

        if (value === 1) return VerificationStatus.VERIFIED;
        if (value === 0) return VerificationStatus.UNVERIFIED;
        return undefined;
      default:
        return undefined;
    }
  };

  const handleUpdateBtnStatus = (resultStatus: number) => {
    switch (props.verificationType) {
      case VerificationType.VERIFYPHONE:
        if (props.phoneType === PhoneType.PRIMARY) {
          props.formInstance.setFieldValue(
            "user_details.primary_phone_validity_status",
            resultStatus,
          );
        } else {
          props.formInstance.setFieldValue(
            "user_details.secondary_phone_validity_status",
            resultStatus,
          );
        }
        break;
      case VerificationType.VERIFYID:
        props.formInstance.setFieldValue(
          "user_details.nin_validity_status",
          resultStatus,
        );
        break;
      default:
        break;
    }
  };

  const handleSubmission = async (data: any) => {
    switch (props.verificationType) {
      case VerificationType.VERIFYPHONE:
        const payload1: any = {
          client: props.client,
          check_attribute: props.phoneType,
          phonenumber: deepDerefrencer(
            props?.formInstance?.values,
            props.formField.name,
          ),
          branch: data.branch,
        };
        setRetryParams(payload1);
        await handleVerifyPhone(payload1);
        break;
      case VerificationType.VERIFYID:
        const payload2: any = {
          client: props.client,
          check_attribute: "nin",
          branch: data.branch,
          nin: data.nin,
          [data.applies_to_temp]: data[data.applies_to_temp],
          document_id: data.document_id,
        };
        setRetryParams(payload2);
        await handleValidId(payload2);
        break;
      default:
        break;
    }
  };

  const handleTryAgain = async () => {
    switch (props.verificationType) {
      case VerificationType.VERIFYPHONE:
        await handleVerifyPhone(retryParams);
        break;
      case VerificationType.VERIFYID:
        await handleValidId(retryParams);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {getVerificationStatus() === undefined && (
        <Button
          disabled={!isValid()}
          size="small"
          variant="contained"
          onClick={handleOpenDrawer}
          sx={{ paddingX: 2, paddingY: 0.5, fontSize: "14px" }}
        >
          <FindReplaceIcon sx={{ fontSize: "16px", marginRight: "2px" }} />
          Verify
        </Button>
      )}
      {getVerificationStatus() === VerificationStatus.VERIFIED && (
        <Chip icon={<VerifiedIcon />} label="MATCHED" color="success" />
      )}

      {getVerificationStatus() === VerificationStatus.UNVERIFIED && (
        <Chip icon={<CancelIcon />} label="NOT MATCHED" color="error" />
      )}

      <DrawerBox
        minWidth={600}
        maxWidth={600}
        selectedDrawerId={`${props.verificationType}-${props.formField.name}`}
        anchor="right"
        onClose={closeDrawer}
      >
        <Box sx={{ height: "100%" }}>
          <Box sx={{ ...styles.title }}>
            <H4 fontWeight={"bold"}>
              {props.verificationType === VerificationType.VERIFYPHONE
                ? "Phone Number "
                : "ID "}{" "}
              Verification
            </H4>
            <Button
              onClick={closeDrawer}
              color="inherit"
              variant={"contained"}
              sx={{ ...styles.closeButton }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <Box sx={{ width: "100%", height: "100%", padding: 2 }}>
            {!verifying && !checkResults && (
              <VerificationButtonForm
                verificationType={props.verificationType}
                handleSubmit={(data) => {
                  handleSubmission(data);
                }}
                initialData={{
                  payload: deepDerefrencer(
                    props?.formInstance?.values,
                    props.formField.name,
                  ),
                  branch: props.branch,
                }}
              />
            )}
            {verifying && (
              <KYCLoader
                verificationType={
                  props.verificationType === VerificationType.VERIFYPHONE
                    ? "phone number"
                    : "NIN"
                }
              />
            )}
            {!verifying && checkResults && (
              <KYCResults
                results={checkResults}
                resultsType={props.verificationType}
                handleRtryAgain={handleTryAgain}
              />
            )}
          </Box>
        </Box>
      </DrawerBox>
    </>
  );
};

const styles = {
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E5EAF2",
    padding: 2,
    position: "sticky",
    backgroundColor: "white",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
};

export default VerificationButton;
