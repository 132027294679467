export const LOAN_STATUS_ACTIVE = "[2,3,4]";
export const LOAN_STATUS_DISBURSEMENT = "[1,8]";
export const LOAN_STATUS_APPLICATIONS = "[0,9]";
export const LOAN_STATUS_CLOSED = "5";
export const LOAN_STATUS_DECLINED = "6";
export const LOAN_STATUS_WRITTEN_OFF = "7";

export const LOAN_TYPES = [
  { name: "Active Loans", value: LOAN_STATUS_ACTIVE },
  { name: "Loan Disbursement", value: LOAN_STATUS_DISBURSEMENT },
  { name: "Loan Applications", value: LOAN_STATUS_APPLICATIONS },
  { name: "Closed Loans", value: LOAN_STATUS_CLOSED },
  { name: "Written Off Loans", value: LOAN_STATUS_WRITTEN_OFF },
  { name: "Declined Loans", value: LOAN_STATUS_DECLINED },
];

export const LOAN_COLLATERAL_TYPES = [
  {
    name: "Cash Collateral",
    value: "cash_collateral",
  },
  {
    name: "Electronics",
    value: "electronics",
  },
  {
    name: "Cars",
    value: "cars",
  },
  {
    name: "Land Titles",
    value: "land_titles",
  },
];

export const LOAN_PURPOSES = [
  {
    name: "Home Improvement",
    value: "home_improvement",
  },
  {
    name: "Education",
    value: "education",
  },
  {
    name: "Medical Expenses",
    value: "medical_expenses",
  },
  {
    name: "Business Expansion",
    value: "business_expansion",
  },
  {
    name: "Debt Consolidation",
    value: "debt_consolidation",
  },
  {
    name: "Vehicle Purchase",
    value: "vehicle_purchase",
  },
  {
    name: "Agricultural Investment",
    value: "agricultural_investment",
  },
  {
    name: "Emergency Expenses",
    value: "emergency_expenses",
  },
  {
    name: "Wedding or Event Expenses",
    value: "wedding_or_event_expenses",
  },
  {
    name: "Vacation or Travel",
    value: "vacation_or_travel",
  },
  {
    name: "Rent or Mortgage Payments",
    value: "rent_or_mortgage_payments",
  },
  {
    name: "Technology or Electronics Purchase",
    value: "technology_or_electronics_purchase",
  },
  {
    name: "Investment",
    value: "investment",
  },
  {
    name: "Business Start-Up Capital",
    value: "business_start_up_capital",
  },
  {
    name: "Other",
    value: "other",
  },
];

export const getLoanPurpose = (value: string) => {
  const loanPurpose = LOAN_PURPOSES.find((purpose) => purpose.value === value);
  return loanPurpose ? loanPurpose.name : "";
};
