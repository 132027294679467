import instance from "../../../api";
export const InstitutionService = {
  async getInstitutions(filters: Object) {
    return instance
      .get("institution/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async getInstitutionDetails(id: any) {
    return instance.get(`institution/${id}/`).then((response) => response.data);
  },

  async createInstitutions(payload: Object) {
    return instance
      .post("institution/", payload)
      .then((response) => response.data);
  },

  async CreateInstitutionLimits(payload: Object, id: string) {
    return instance
      .patch(`institution/${id}/general-settings/`, payload)
      .then((response) => response.data);
  },

  async updateInstitutions(payload: Object, id: string) {
    const { logo, ...form }: any = payload;

    if (logo) {
      const formData = new FormData();
      formData.append("logo", logo);

      // loop through form fields and append to formData
      Object.keys(form).forEach((key) => {
        formData.append(key, form[key]);
      });

      return instance
        .patch(`institution/${id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => response.data);
    }

    return instance
      .patch(`institution/${id}/`, form)
      .then((response) => response.data);
  },

  async deleteInstitutions(id: string) {
    return instance
      .delete(`institution/${id}/`)
      .then((response) => response.data);
  },

  async updateClicIntergration(id: string, payload: any) {
    return instance
      .patch(`institution/${id}/general-settings/`, payload)
      .then((response) => response.data);
  },
  async deactivateInstitution(payload: Object, id: string) {
    return instance
      .patch(`institution/${id}/institution-status/`, payload)
      .then((response) => response.data);
  },
};
