import React, { FC, useEffect } from "react";
import { VerificationType } from "./VerificationButton";
import { useFormik } from "formik";
import {
  deepDerefrencer,
  getFieldByName,
  getInitialValues,
} from "../../../../utils/form_factory";
import { NINVerificationFields } from "./models/NINVerificationFields";
import { PhoneVerificationFields } from "./models/PhoneNumberFields";
import { NINValidations, PhoneValidations } from "./models/Validations";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import FormTextInput from "../../FormComponents/FormTextInput";
import FormNinInput from "../../FormComponents/FormNinInput";
import FormPhoneInput from "../../FormComponents/FormPhoneInput";
import { BranchService } from "../../../../pages/Admin/Branch/services/Branch.service";
import FormBranchField from "../../FormComponents/FormBranchField";
import { FormDateInput } from "../../FormComponents/FormDateInput";
import { init } from "@sentry/browser";

interface VerificationButtonFormProps {
  verificationType: VerificationType;
  handleSubmit: (data: any) => void;
  initialData?: any;
}

const VerificationButtonForm: FC<VerificationButtonFormProps> = (props) => {
  const [checkField, setCheckField] = React.useState<string | undefined>(
    undefined,
  );

  const branchFetcher = async (
    search: string,
    setData: (results: any) => void,
    extraFilters?: any,
  ) => {
    try {
      const moreFilters = extraFilters ? extraFilters : {};
      const { results }: any = await BranchService.getBranch({
        search,
        ...moreFilters,
        detail: 1,
      });
      setData(results);
    } catch (error) {
      setData([]);
    }
  };

  const getFormFields = () => {
    const formFields =
      props.verificationType === VerificationType.VERIFYPHONE
        ? PhoneVerificationFields(branchFetcher)
        : NINVerificationFields(branchFetcher, checkField || "");
    return formFields;
  };

  const formFields = getFormFields();

  const getFormValidationSchema = () => {
    const validation =
      props.verificationType === VerificationType.VERIFYPHONE
        ? PhoneValidations
        : NINValidations;
    return validation;
  };

  const formGroup = useFormik({
    initialValues: getInitialValues(formFields),
    validationSchema: getFormValidationSchema(),
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) =>
      props.handleSubmit({
        ...values,
        branch: values.branch?.id,
      }),
  });

  useEffect(() => {
    if (props.initialData?.payload) {
      props.verificationType === VerificationType.VERIFYPHONE
        ? formGroup.setFieldValue("phonenumber", props?.initialData?.payload)
        : formGroup.setFieldValue("nin", props?.initialData?.payload);
    }

    if (props?.initialData?.branch) {
      formGroup.setFieldValue("branch", props?.initialData?.branch);
    }
  }, [props.initialData]);

  useEffect(() => {
    if (formGroup.values.applies_to_temp) {
      setCheckField(formGroup.values.applies_to_temp);
    }
  }, [formGroup.values.applies_to_temp]);

  return (
    <form name="verfication-form" onSubmit={formGroup.handleSubmit}>
      <Box sx={{ width: "100%" }}>
        {props.verificationType === VerificationType.VERIFYID && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item {...getFieldByName(formFields, "branch").uiBreakpoints}>
              <FormBranchField
                formControl={formGroup}
                {...getFieldByName(formFields, "branch")}
              />
            </Grid>

            <Grid item {...getFieldByName(formFields, "nin").uiBreakpoints}>
              <FormNinInput
                formControl={formGroup}
                {...getFieldByName(formFields, "nin")}
              />
            </Grid>

            <Grid
              item
              {...getFieldByName(formFields, "document_id").uiBreakpoints}
            >
              <FormNinInput
                formControl={formGroup}
                {...getFieldByName(formFields, "document_id")}
              />
            </Grid>

            <Grid
              item
              {...getFieldByName(formFields, "applies_to_temp")?.uiBreakpoints}
            >
              <FormControl>
                <FormLabel
                  sx={styles.label}
                  id="demo-row-radio-buttons-group-label"
                >
                  {getFieldByName(formFields, "applies_to_temp").label}
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name={getFieldByName(formFields, "applies_to_temp").name}
                  value={deepDerefrencer(formGroup.values, "applies_to_temp")}
                  onChange={formGroup.handleChange}
                >
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                      value="surname"
                      control={<Radio />}
                      label="Surname"
                    />
                    <FormControlLabel
                      value="given_name"
                      control={<Radio />}
                      label="Given Name"
                    />
                    <FormControlLabel
                      value="other_name"
                      control={<Radio />}
                      label="Other Name"
                    />
                    <FormControlLabel
                      value="date_of_birth"
                      control={<Radio />}
                      label="Date of Birth"
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Grid>

            {!!checkField &&
              (getFieldByName(formFields, checkField).name ===
              "date_of_birth" ? (
                <Grid
                  item
                  {...getFieldByName(formFields, checkField).uiBreakpoints}
                >
                  <FormDateInput
                    {...getFieldByName(formFields, checkField)}
                    formControl={formGroup}
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  {...getFieldByName(formFields, checkField).uiBreakpoints}
                >
                  <FormTextInput
                    {...getFieldByName(formFields, checkField)}
                    formControl={formGroup}
                  />
                </Grid>
              ))}
          </Grid>
        )}

        {props.verificationType === VerificationType.VERIFYPHONE && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item {...getFieldByName(formFields, "branch").uiBreakpoints}>
              <FormBranchField
                formControl={formGroup}
                {...getFieldByName(formFields, "branch")}
              />
            </Grid>

            <Grid
              item
              {...getFieldByName(formFields, "phonenumber").uiBreakpoints}
            >
              <FormPhoneInput
                formControl={formGroup}
                {...getFieldByName(formFields, "phonenumber")}
              />
            </Grid>
          </Grid>
        )}
      </Box>

      <Button
        sx={{ marginTop: 2 }}
        onClick={formGroup.submitForm}
        variant="contained"
        size="small"
        color="primary"
        disabled={!formGroup.isValid}
      >
        Verify{" "}
        {props.verificationType === VerificationType.VERIFYPHONE
          ? "Phone"
          : "NIN"}
      </Button>
    </form>
  );
};

const styles = {
  label: {
    color: "#1c2437",
    fontWeight: 600,
  },
  hidden: {
    display: "none",
  },
};

export default VerificationButtonForm;
