import { lazy, Suspense as ReactSuspense } from "react";
import LoadingScreen from "../components/LoadingScreen";

const Suspense: any = ReactSuspense;

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const RouteLazyLoader: any = {
  // AUTHENTICATION ROUTES
  Login: Loadable(lazy(() => import("../pages/Authentication/Login/Login"))),
  ForgotPassword: Loadable(
    lazy(() => import("../pages/Authentication/ForgotPassword/ForgotPassword")),
  ),
  ResetPassword: Loadable(
    lazy(() => import("../pages/Authentication/ResetPassword/ResetPassword")),
  ),

  // CHANGE PASSWORD ROUTES
  ChangePassword: Loadable(
    lazy(
      () =>
        import("../pages/Dashboard/enforced-password-update/UpdatePassword"),
    ),
  ),

  // DASHBOARD ROUTES
  DashboardDashboard: Loadable(
    lazy(() => import("../pages/Dashboard/Dashboard")),
  ),

  // USER MANAGEMENT ROUTES
  UserProfile: Loadable(
    lazy(() => import("../pages/UserManagement/UserProfile")),
  ),

  // CLIENT ROUTES
  Clients: Loadable(lazy(() => import("../pages/Clients/Clients"))),
  ClientForm: Loadable(lazy(() => import("../pages/Clients/ClientsForm"))),
  ClientProfile: Loadable(lazy(() => import("../pages/Clients/ClientProfile"))),

  // LOAN ROUTES
  Loans: Loadable(lazy(() => import("../pages/Loans/Loans"))),
  LoanDetail: Loadable(lazy(() => import("../pages/Loans/LoanDetails"))),
  LoanForm: Loadable(lazy(() => import("../pages/Loans/LoansForm"))),
  LoanCollateral: Loadable(
    lazy(() => import("../pages/Loans/CollateralRegister/Collateral")),
  ),
  LoanCalculator: Loadable(
    lazy(() => import("../pages/Loans/LoansCalculator")),
  ),

  // SAVINGS ROUTES
  RegisterDeposit: Loadable(
    lazy(() => import("../pages/Savings/Deposit/RegisterDeposit")),
  ),
  RegisterWithdraw: Loadable(
    lazy(() => import("../pages/Savings/Withdraw/RegisterWithdraw")),
  ),
  Transactions: Loadable(
    lazy(() => import("../pages/Savings/Transactions/Transactions")),
  ),
  DepositCalculator: Loadable(
    lazy(() => import("../pages/Savings/DepositCalculator/DepositCalculator")),
  ),
  DisburseInterest: Loadable(
    lazy(() => import("../pages/Savings/DisburseInterest/DisburseInterest")),
  ),

  // WORKFLOW ROUTES
  WorkflowRequest: Loadable(
    lazy(() => import("../pages/WorkflowRequest/WorkflowRequest")),
  ),

  // SHARES ROUTES
  ShareRegister: Loadable(
    lazy(() => import("../pages/Shares/ShareRegister/ShareRegister")),
  ),
  PurchaseShares: Loadable(
    lazy(() => import("../pages/Shares/PurchaseShares/PurchaseShares")),
  ),
  TransferShares: Loadable(
    lazy(() => import("../pages/Shares/TransferShares/TransferShares")),
  ),
  SharePurchases: Loadable(
    lazy(() => import("../pages/Shares/SharePurchases/PurchaseTransactions")),
  ),
  ShareTransfers: Loadable(
    lazy(() => import("../pages/Shares/ShareTransfers/TransferTransactions")),
  ),
  Dividends: Loadable(
    lazy(() => import("../pages/Shares/Dividends/Dividends")),
  ),
  DividendPayout: Loadable(
    lazy(() => import("../pages/Shares/DividendPayouts/DividendPayout")),
  ),
  DividendPayoutCreate: Loadable(
    lazy(() => import("../pages/Shares/DividendPayouts/DividendsForm")),
  ),
  SellShares: Loadable(
    lazy(() => import("../pages/Shares/SellShares/SellShares")),
  ),

  // ACCOUNTING ROUTES
  Accounting: Loadable(
    lazy(() => import("../pages/Accounting/CoreAccounting/CoreAccounting")),
  ),
  TransactionAccounts: Loadable(
    lazy(
      () =>
        import("../pages/Accounting/TransactionAccounts/TransactionAccounts"),
    ),
  ),
  CashAccountDetails: Loadable(
    lazy(
      () =>
        import(
          "../pages/Accounting/TransactionAccounts/CashAccounts/CashAccountDetails/CashAccountDetails"
        ),
    ),
  ),
  BankAccountDetails: Loadable(
    lazy(
      () =>
        import("../pages/Accounting/TransactionAccounts/BankAccountDetails"),
    ),
  ),
  SafeAccountDetails: Loadable(
    lazy(
      () =>
        import("../pages/Accounting/TransactionAccounts/SafeAccountDetails"),
    ),
  ),
  CashTransfers: Loadable(
    lazy(() => import("../pages/Accounting/CashTransfers/CashTransfers")),
  ),
  Supplier: Loadable(
    lazy(() => import("../pages/Accounting/Suppliers/Suppliers")),
  ),
  Payables: Loadable(
    lazy(() => import("../pages/Accounting/Payables/Payables")),
  ),
  Creditors: Loadable(
    lazy(() => import("../pages/Accounting/Creditors/Creditor")),
  ),
  Receivables: Loadable(
    lazy(() => import("../pages/Accounting/Receivables/Receivable")),
  ),
  Debtors: Loadable(lazy(() => import("../pages/Accounting/Debtors/Debtor"))),
  StaffTillSheet: Loadable(
    lazy(
      () =>
        import(
          "../pages/Accounting/CoreAccounting/StaffTillSheet/StaffTillSheet"
        ),
    ),
  ),

  // SMS BANKING ROUTES
  SMSSettings: Loadable(
    lazy(() => import("../pages/SMSBanking/Settings/Settings")),
  ),
  SMSCreateAutoType: Loadable(
    lazy(() => import("../pages/SMSBanking/Settings/AutoSMS/AutoSmsTypeForm")),
  ),
  SMSOutBox: Loadable(lazy(() => import("../pages/SMSBanking/OutBox/Outbox"))),
  SMSCreateOutBox: Loadable(
    lazy(() => import("../pages/SMSBanking/OutBox/Outboxes/OutBoxForm")),
  ),
  SMSCreateBulkOutBox: Loadable(
    lazy(
      () => import("../pages/SMSBanking/OutBox/Outboxes/ScheduledOutBoxForm"),
    ),
  ),

  // DATA IMPORTER ROUTES
  ImportClients: Loadable(
    lazy(() => import("../pages/DataImporter/Clients/ImportedClient")),
  ),
  ImportClientRecords: Loadable(
    lazy(() => import("../pages/DataImporter/Clients/Records")),
  ),
  ImportClientAccounts: Loadable(
    lazy(
      () =>
        import("../pages/DataImporter/ClientAccounts/ImportedClientAccounts"),
    ),
  ),
  ImportClientAccountsRecords: Loadable(
    lazy(() => import("../pages/DataImporter/ClientAccounts/Records")),
  ),
  ImportLoans: Loadable(
    lazy(() => import("../pages/DataImporter/Loans/ImportedLoans")),
  ),
  ImportLoanPayments: Loadable(
    lazy(
      () => import("../pages/DataImporter/LoanPayment/ImportedLoanPayments"),
    ),
  ),
  ImportLoanRecords: Loadable(
    lazy(() => import("../pages/DataImporter/Loans/Records")),
  ),
  ImportLoanPaymentRecords: Loadable(
    lazy(() => import("../pages/DataImporter/LoanPayment/Records")),
  ),
  ImportSavingTransactions: Loadable(
    lazy(
      () =>
        import(
          "../pages/DataImporter/SavingTransactions/ImportedSavingTransactions"
        ),
    ),
  ),
  ImportSavingTransactionRecords: Loadable(
    lazy(() => import("../pages/DataImporter/SavingTransactions/Records")),
  ),
  ImportShares: Loadable(
    lazy(() => import("../pages/DataImporter/Shares/ImportedShares")),
  ),
  ImportSharesRecords: Loadable(
    lazy(() => import("../pages/DataImporter/Shares/Records")),
  ),
  ImportCoa: Loadable(
    lazy(() => import("../pages/DataImporter/ChartOfAccount/ImportedCoa")),
  ),
  ImportCoaRecords: Loadable(
    lazy(() => import("../pages/DataImporter/ChartOfAccount/Records")),
  ),

  // REPORTS ROUTES
  BalanceSheet: Loadable(
    lazy(() => import("../pages/Reports/BalanceSheet/BalanceSheet")),
  ),
  CashflowStatement: Loadable(
    lazy(() => import("../pages/Reports/CashflowStatement/CashflowStatement")),
  ),
  TrialBalance: Loadable(
    lazy(() => import("../pages/Reports/TrialBalance/TrialBalance")),
  ),
  IncomeStatement: Loadable(
    lazy(() => import("../pages/Reports/IncomeStatement/IncomeStatement")),
  ),
  AgeingReport: Loadable(
    lazy(() => import("../pages/Reports/AgeingReport/AgeingReport")),
  ),
  LoanProvisionReport: Loadable(
    lazy(
      () => import("../pages/Reports/LoanProvisionReport/LoanProvisionReport"),
    ),
  ),
  LoanArrearsReport: Loadable(
    lazy(() => import("../pages/Reports/LoanArrears/LoanArrears")),
  ),
  LoanStatusReport: Loadable(
    lazy(() => import("../pages/Reports/LoanStatus/LoanStatus")),
  ),
  LoanDisbursementReport: Loadable(
    lazy(() => import("../pages/Reports/LoanDisbursement/LoanDisbursement")),
  ),
  PortfolioAtRiskReport: Loadable(
    lazy(() => import("../pages/Reports/PortfolioAtRisk/PortfolioAtRisk")),
  ),
  LoanRepaymentReport: Loadable(
    lazy(() => import("../pages/Reports/LoanRepayment/LoanRepayment")),
  ),
  ClientReport: Loadable(
    lazy(() => import("../pages/Reports/ClientReport/ClientReport")),
  ),
  MembershipSchedule: Loadable(
    lazy(() => import("../pages/Reports/Membership/Membership")),
  ),
  ExpensesReport: Loadable(
    lazy(() => import("../pages/Reports/ExpensesReport/ExpensesReport")),
  ),
  FeesReport: Loadable(
    lazy(() => import("../pages/Reports/FeesReport/FeesReport")),
  ),
  LoanOfficerReport: Loadable(
    lazy(() => import("../pages/Reports/LoanOfficerReport/LoanOfficer")),
  ),
  LoanOfficerSummaryReport: Loadable(
    lazy(
      () =>
        import("../pages/Reports/LoanOfficerSummaryReport/LoanOfficerSummary"),
    ),
  ),
  SavingsReport: Loadable(
    lazy(() => import("../pages/Reports/SavingReport/SavingReport")),
  ),
  SnapshotReport: Loadable(
    lazy(() => import("../pages/Reports/SnapshotReport/SnapshotReport")),
  ),
  SummaryCollection: Loadable(
    lazy(
      () =>
        import("../pages/Reports/SummaryCollection/SummaryCollectionReport"),
    ),
  ),
  SavingsOfficerReport: Loadable(
    lazy(() => import("../pages/Reports/SavingsOfficer/SavingsOfficerReport")),
  ),
  CumulativeSavingsReport: Loadable(
    lazy(
      () =>
        import(
          "../pages/Reports/CumulativeSavingReport/CumulativeSavingsReport"
        ),
    ),
  ),
  ParAgingReport: Loadable(
    lazy(() => import("../pages/Reports/ParAgingReport/ParAgingReport")),
  ),
  LoanBookReport: Loadable(
    lazy(() => import("../pages/Reports/LoanBook/LoanBook")),
  ),

  NonPhysicalAssetReport: Loadable(
    lazy(
      () =>
        import(
          "../pages/Reports/NonPhysicalAssetReport/NonPhysicalassetReport"
        ),
    ),
  ),
  PhysicalAssetReport: Loadable(
    lazy(
      () => import("../pages/Reports/PhysicalassetReport/PhysicalassetReport"),
    ),
  ),
  PhysicalAssetSaleReport: Loadable(
    lazy(
      () =>
        import("../pages/Reports/PhysicalassetReport/PhysicalAssetSaleReport"),
    ),
  ),
  ExpectedLoanRepaymentReport: Loadable(
    lazy(
      () =>
        import("../pages/Reports/ExpectedLoanRepayment/ExpectedLoanRepayment"),
    ),
  ),
  LoanRepaymentTransaction: Loadable(
    lazy(
      () =>
        import(
          "../pages/Reports/loanRepaymentTransactionReport/LoanRepaymentReport"
        ),
    ),
  ),

  // INSTUTION ADMIN ROUTES
  BankSettings: Loadable(
    lazy(() => import("../pages/Admin/BankSettings/BankSettings")),
  ),
  Branches: Loadable(lazy(() => import("../pages/Admin/Branch/Branch"))),
  Workflow: Loadable(lazy(() => import("../pages/Admin/Workflows/Workflow"))),
  WorkflowLevels: Loadable(
    lazy(() => import("../pages/Admin/Workflows/Workflow")),
  ),
  CreateRole: Loadable(
    lazy(() => import("../pages/Admin/RoleAndPersmissions/AddRoles")),
  ),
  RolesAndPermissions: Loadable(
    lazy(
      () => import("../pages/Admin/RoleAndPersmissions/RolesAndPermissions"),
    ),
  ),
  Staff: Loadable(lazy(() => import("../pages/Admin/Staff/Staff"))),
  StaffForm: Loadable(lazy(() => import("../pages/Admin/Staff/StaffForm"))),
  StaffDetails: Loadable(
    lazy(() => import("../pages/Admin/Staff/StaffDetail")),
  ),
  FeesSettings: Loadable(
    lazy(() => import("../pages/Admin/FeesSettings/FeesSettings")),
  ),
  LoanSettings: Loadable(
    lazy(() => import("../pages/Admin/LoanSettings/LoanSettings")),
  ),
  LoanProductSettings: Loadable(
    lazy(
      () =>
        import("../pages/Admin/LoanSettings/LoanProducts/LoanProductDetails"),
    ),
  ),
  LoanProductForm: Loadable(
    lazy(
      () => import("../pages/Admin/LoanSettings/LoanProducts/LoanProductForm"),
    ),
  ),
  SavingsSettings: Loadable(
    lazy(() => import("../pages/Admin/SavingSettings/SavingSettings")),
  ),
  SavingsProductForm: Loadable(
    lazy(
      () =>
        import(
          "../pages/Admin/SavingSettings/SavingProducts/SavingSettingsForm"
        ),
    ),
  ),
  SharesSettings: Loadable(
    lazy(
      () =>
        import(
          "../pages/Admin/GeneralSettings/Settings/ShareSettings/SharesSettings"
        ),
    ),
  ),
  WorkingHours: Loadable(
    lazy(() => import("../pages/Admin/WorkingHours/WorkingHours")),
  ),
  GeneralSettings: Loadable(
    lazy(() => import("../pages/Admin/GeneralSettings")),
  ),

  // ZIMBA ADMIN ROUTES
  Institution: Loadable(lazy(() => import("../pages/Institution/Institution"))),
  InstitutionCreate: Loadable(
    lazy(() => import("../pages/Institution/InstitutionForm")),
  ),
  InstitutionDetails: Loadable(
    lazy(() => import("../pages/Institution/InstitutionDetail/index")),
  ),
  InstitutionAdmin: Loadable(
    lazy(() => import("../pages/InstitutionAdmin/InstitutionAdmin")),
  ),
  MasterAdmin: Loadable(
    lazy(() => import("../pages/MasterAdmin/MasterAdminList")),
  ),
  AddMasterAdmin: Loadable(
    lazy(() => import("../pages/MasterAdmin/RegisterMasterAdmin")),
  ),
  MasterAdminDetails: Loadable(
    lazy(() => import("../pages/MasterAdmin/MasterAdminDetails")),
  ),
  KYCSettings: Loadable(lazy(() => import("../pages/KYCSettings/index"))),

  // ERROR ROUTES
  Error404: Loadable(lazy(() => import("../pages/404"))),
  Error403: Loadable(lazy(() => import("../pages/403"))),

  // Assets ROUTES
  PhyscialAssets: Loadable(
    lazy(() => import("../pages/Assets/Physcial/PhysicalAssets")),
  ),
  RegisterAssets: Loadable(
    lazy(() => import("../pages/Assets/Physcial/RegisterAssets")),
  ),
  PhyscialAssetsDetails: Loadable(
    lazy(() => import("../pages/Assets/Physcial/PysicalAssetsDetails")),
  ),
  NonPhyscialAssets: Loadable(
    lazy(() => import("../pages/Assets/NonPhysical/NonPhysicalAssets")),
  ),
  RegisterNonPhyscialAssets: Loadable(
    lazy(() => import("../pages/Assets/NonPhysical/RegisterNonPhysicalAssets")),
  ),
  NonPhyscialAssetsDetails: Loadable(
    lazy(
      () => import("../pages/Assets/NonPhysical/ViewNonPhysicalAssetDetails"),
    ),
  ),

  // Deleted CLIENT
  Trash: Loadable(lazy(() => import("../pages/Trash/Clients/Clients"))),
  LoanProductsTrash: Loadable(
    lazy(() => import("../pages/Trash/LoanProducts/LoanProduct")),
  ),
  SavingsProductsTrash: Loadable(
    lazy(() => import("../pages/Trash/SavingsProducts/SavingSettings")),
  ),
  AccountTrash: Loadable(
    lazy(() => import("../pages/Trash/Accounts/ChartOfAccounts")),
  ),

  // KYC ROUTES
  KycService: Loadable(lazy(() => import("../pages/KYC/KYCServices"))),
  KycCheck: Loadable(lazy(() => import("../pages/KYC/KYCCheck"))),
};

export default RouteLazyLoader;
